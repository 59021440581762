import { render, staticRenderFns } from "./DonghangPowerBall.vue?vue&type=template&id=5b767214&scoped=true&"
import script from "./DonghangPowerBall.vue?vue&type=script&lang=js&"
export * from "./DonghangPowerBall.vue?vue&type=script&lang=js&"
import style0 from "./DonghangPowerBall.vue?vue&type=style&index=0&id=5b767214&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b767214",
  null
  
)

export default component.exports